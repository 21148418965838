<template>
    <v-container fluid>
        <v-dialog
            persistent
            max-width="960px"
            transition="dialog-bottom-transition"
            v-model="dialogForm"
        >
            <v-form @submit.prevent="saveTracking">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                    <v-card flat >
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogAdd" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>

                            </v-row>

                            <v-row class="mt-5">
                                <v-col cols="12" md="12">
                                    <v-row>
                                        <v-col class="py-0" cols="12" sm="4">
                                            <ValidationProvider ref="deal" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('deal.self')}}</div>
                                                <v-autocomplete v-model="deal" :items="dealItems"
                                                                :error="!valid"
                                                                :search-input.sync="dealSearching"
                                                                item-text="number" item-value="id"
                                                                :loading="loadingDeals"
                                                                :no-data-text="dealSearching ? $t('nothing_found_by',{'search': dealSearching}) : $t('nothing_found_name')"
                                                                :label="$t('deal.self')"
                                                                @click:clear="dealItems=[]"
                                                                @click="clearDeals"
                                                                autocomplete="off"
                                                                hide-details
                                                                :error-messages="errors"
                                                                :disabled="loadingDeals || disableDeals"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable>
                                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.number"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.admin.name"></span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.number"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.admin.name"></span>
                                                        </span>
                                                    </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="8">
                                            <ValidationProvider ref="uved" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('uved.self')}}</div>
                                                <v-autocomplete v-model="uved" :items="uvedItems"
                                                                :error="!valid"
                                                                :search-input.sync="uvedSearching"
                                                                item-text="name" item-value="id"
                                                                :loading="loadingUveds"
                                                                :no-data-text="uvedSearching ? $t('nothing_found_by',{'search': uvedSearching}) : $t('nothing_found_name')"
                                                                :label="$t('uved.self')"
                                                                @click:clear="uvedSearching=null;uved=null;uvedItems=[];"
                                                                @click="clearUveds"
                                                                @click:append="redirectToUved(uved)"
                                                                autocomplete="off"
                                                                hide-details
                                                                :error-messages="errors"
                                                                :disabled="loadingUveds || disableUveds"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable>
                                                    <template v-slot:item="{ attrs, item, parent, selected }">
                                                        <span class="search-list" @click="setCompanyUVED(item)">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                                    </template>
                                                    <template v-slot:selection="{ attrs, item, parent, selected }">
                                                        <span class="search-list">
                                                            <span v-html="item.name"></span>
                                                            <span class="ml-2 hidden-is-empty"
                                                                  v-text="item.bin_iin"></span>
                                                        </span>
                                                    </template>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="departure" rules="required|min:1" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('departures')}}</div>
                                                <v-autocomplete v-model="departure" :items="departureItems"
                                                                :error="!valid" :error-messages="errors"
                                                                :search-input.sync="departureSearching"
                                                                item-text="geocoder_name" item-value="id"
                                                                :loading="loadingDepartures"
                                                                @click="clearDepartures"
                                                                :no-data-text="departureSearching ? $t('nothing_found_by',{'search': departureSearching}) : $t('nothing_found_departures')"
                                                                :label="$t('departures')" @click:clear="departureItems=[]"
                                                                return-object  autocomplete="off"
                                                                append-outer-icon="mdi-plus"
                                                                @click:append-outer="dialogFormStation=true;add_type_station='departure'"
                                                                outlined
                                                                solo
                                                                flat
                                                                hide-details
                                                                dense
                                                                :disabled="loading"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="destination" rules="required|min:1" v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('destination')}}</div>

                                                <v-autocomplete v-model="destination" :items="destinationItems"
                                                                :error="!valid" :error-messages="errors"
                                                                :search-input.sync="destinationSearching"
                                                                item-text="geocoder_name" item-value="id"
                                                                :loading="loadingDestination"
                                                                @click="clearDestination"
                                                                :no-data-text="destinationSearching ? $t('nothing_found_by',{'search': destinationSearching}) : $t('nothing_found_destination')"
                                                                :label="$t('destination')" @click:clear="destinationItems=[]"
                                                                return-object  autocomplete="off"
                                                                append-outer-icon="mdi-plus"
                                                                @click:append-outer="dialogFormStation=true;add_type_station='destination'"
                                                                outlined
                                                                solo
                                                                flat
                                                                hide-details
                                                                dense
                                                                :disabled="loading"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable>
                                                </v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="type_transport" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('type_transport')}}</div>

                                                <v-autocomplete v-model="type_transport" :items="typeTransportItems"
                                                                item-text="name" item-value="id"
                                                                :label="$t('type_transport')"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                :error="!valid"
                                                                hide-details
                                                                :error-messages="errors"
                                                                :disabled="loadingTypeTransport"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable></v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="loading_type" rules="required|min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('loading_type')}}</div>
                                                <v-autocomplete v-model="loading_type" :items="loadingTypeItems"
                                                                item-text="name" item-value="id"
                                                                :label="$t('loading_type')"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                :error="!valid"
                                                                hide-details
                                                                :error-messages="errors"
                                                                :disabled="loadingLoadingType"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable></v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="type_container" rules="min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('type_container')}}</div>
                                                <v-autocomplete v-model="type_container" :items="typeContainerItems"
                                                                item-text="name" item-value="id"
                                                                :label="$t('type_container')"
                                                                :error="!valid"
                                                                hide-details
                                                                :error-messages="errors"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                :disabled="loadingTypeContainer"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable></v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="container_number" rules="min:2|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('container_number')}}</div>
                                                <v-text-field v-model="container_number" type="text"
                                                              :error="!valid"
                                                              :label="$t('container_number')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="platform_number" rules="min:2|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('platform_number')}}</div>
                                                <v-text-field v-model="platform_number" type="text"
                                                              :error="!valid"
                                                              :label="$t('platform_number')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="platform_number_knr" rules="min:2|max:255"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('platform_number_knr')}}</div>
                                                <v-text-field v-model="platform_number_knr" type="text"
                                                              :error="!valid"
                                                              :label="$t('platform_number_knr')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="status" rules="min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('status')}}</div>
                                                <v-autocomplete v-model="status" :items="statusItems"
                                                                item-text="code" item-value="id"
                                                                :label="$t('status')"
                                                                :error="!valid"
                                                                hide-details
                                                                :error-messages="errors"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                :disabled="loading"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable
                                                                @change="getSubStatus()"
                                                                @click:clear="status=null;sub_status=null;subStatusItems=[];"
                                                ></v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <ValidationProvider ref="sub_status" rules="min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('sub_status')}}</div>
                                                <v-autocomplete v-model="sub_status" :items="subStatusItems"
                                                                item-text="name" item-value="id"
                                                                :label="$t('sub_status')"
                                                                :error="!valid"
                                                                hide-details
                                                                :error-messages="errors"
                                                                outlined
                                                                solo
                                                                flat
                                                                dense
                                                                :disabled="loading"
                                                                :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                color="primary"
                                                                class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                clearable
                                                ></v-autocomplete>
                                            </ValidationProvider>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <v-menu v-model="menuDateSending" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <ValidationProvider ref="date_sending" name="date_sending" rules="date_format"
                                                                        v-slot="{ errors, valid }">
                                                        <div class="input_label">{{$t('date_sending')}}</div>

                                                        <v-text-field v-model="date_sending" :error-messages="errors"
                                                                      :label="$t('date_sending')"
                                                                      readonly v-on="on"
                                                                      @click:clear="date_sending = null"
                                                                      outlined
                                                                      hide-details
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      :disabled="loading"
                                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                      clearable></v-text-field>
                                                    </ValidationProvider>
                                                </template>
                                                <v-date-picker ref="picker" v-model="date_sending"
                                                               :min="$moment().format('YYYY-MM-DD')"
                                                               :locale="lang" @change="menuDateSending = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <v-menu v-model="dateMenuPr" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <div class="input_label">{{$t('arrival_date_at_destination')}}</div>
                                                    <ValidationProvider ref="date_pr" name="date__pr" rules="date_format"
                                                                        v-slot="{ errors, valid }">
                                                        <v-text-field v-model="date_pr" :error-messages="errors"
                                                                      :label="$t('arrival_date_at_destination')"
                                                                      readonly v-on="on"
                                                                      @click:clear="date_pr = null"
                                                                      outlined
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      hide-details
                                                                      :disabled="loading"
                                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                      clearable></v-text-field>
                                                    </ValidationProvider>
                                                </template>
                                                <v-date-picker ref="picker" v-model="date_pr"
                                                               :min="$moment().format('YYYY-MM-DD')"
                                                               :locale="lang" @change="dateMenuPr = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <v-menu v-model="dateMenuGr" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <ValidationProvider ref="date_gr" name="date_gr" rules="date_format"
                                                                        v-slot="{ errors, valid }">
                                                        <div class="input_label">{{$t('date_of_arrival_at_the_border')}}</div>

                                                        <v-text-field v-model="date_gr" :error-messages="errors"
                                                                      :label="$t('date_of_arrival_at_the_border')"
                                                                      readonly v-on="on"
                                                                      @click:clear="date_gr = null"
                                                                      outlined
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      hide-details
                                                                      :disabled="loading"
                                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                      clearable></v-text-field>
                                                    </ValidationProvider>
                                                </template>
                                                <v-date-picker ref="picker" v-model="date_gr"
                                                               :min="$moment().format('YYYY-MM-DD')"
                                                               :locale="lang" @change="dateMenuGr = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="6">
                                            <v-menu v-model="dateMenuBorder" :close-on-content-click="false" :nudge-right="40"
                                                    transition="scale-transition" offset-y min-width="290px">
                                                <template v-slot:activator="{ on }">
                                                    <div class="input_label">{{$t('date_of_exit_from_border')}}</div>

                                                    <ValidationProvider ref="date_border" name="date_from" rules="date_format"
                                                                        v-slot="{ errors, valid }">
                                                        <v-text-field v-model="date_border" :error-messages="errors"
                                                                      :label="$t('date_of_exit_from_border')"
                                                                      readonly v-on="on"
                                                                      @click:clear="date_border = null"
                                                                      outlined
                                                                      hide-details
                                                                      solo
                                                                      flat
                                                                      dense
                                                                      :disabled="loading"
                                                                      :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                                                      color="primary"
                                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                                      clearable></v-text-field>
                                                    </ValidationProvider>
                                                </template>
                                                <v-date-picker ref="picker" v-model="date_border"
                                                               :min="$moment().format('YYYY-MM-DD')"
                                                               :locale="lang" @change="dateMenuBorder = false"></v-date-picker>
                                            </v-menu>
                                        </v-col>
                                        <v-col v-if="false" class="py-0" cols="12" sm="12">
                                            <ValidationProvider ref="distance" rules="min:1"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('distance')}}</div>
                                                <v-text-field v-model="distance" type="text"
                                                              :error="!valid"
                                                              :label="$t('distance')"
                                                              hide-details
                                                              :error-messages="errors"
                                                              :disabled="loading"
                                                              outlined
                                                              solo
                                                              flat
                                                              dense
                                                              :height="$vuetify.breakpoint.xsOnly ? 45 : 31"
                                                              color="primary"
                                                              class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                              clearable>
                                                </v-text-field>
                                            </ValidationProvider>
                                        </v-col>

                                        <v-col v-if="false" class="py-0"  cols="12">
                                            <ValidationProvider ref="comment" rules="min:3|max:65535"
                                                                v-slot="{ errors, valid }">
                                                <div class="input_label">{{$t('comment')}}</div>
                                                <v-textarea v-model="comment" type="text" :error-messages="errors"
                                                            :disabled="loading" :label="$t('comment')" rows="5"
                                                            color="primary"
                                                            auto-grow
                                                            clearable outlined
                                                            hide-details
                                                            full-width
                                                            background-color="white lighten-2"
                                                            class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_textarea">
                                                </v-textarea>
                                            </ValidationProvider>
                                        </v-col>
                                    </v-row>
                                </v-col>


                            </v-row>

                        </v-card-text>
                        <v-card-actions class="px-4 py-7">

                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                :to="{name: 'deal'}"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>



                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>


        <v-dialog
            persistent
            max-width="1200px"
            transition="dialog-bottom-transition"
            v-model="dialogFormStation"
        >
            <v-form @submit.prevent="saveStation">
                <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">

                    <v-card flat >
                        <v-card-text class="py-0">
                            <v-row>
                                <v-col cols="12">
                                    <v-row class="d-flex mt-5">
                                        <v-col cols="10" class="ma-auto">
                                            <span class="title_menu">{{ heading }}</span>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end">
                                            <v-icon @click="closeDialogStation" :title="$t('close')" color="primary" >mdi-close</v-icon>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                            <v-row class="mt-2">
                                <v-col class="py-0" cols="12">
                                    <v-tabs v-model="tab" background-color="transparent"
                                            :right="$vuetify.breakpoint.mdAndUp"
                                            :grow="$vuetify.breakpoint.smAndDown"
                                            color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                        <v-tab ref="language" v-for="language in languages" :key="language">
                                            <span :class="exist_translations[language] ? '' : 'red--text '">
                                                {{ language }}
                                            </span>
                                        </v-tab>
                                    </v-tabs>
                                </v-col>
                            </v-row>

                            <v-row class="mt-5">
                                <v-col class="py-0" cols="12" sm="4">
                                    <ValidationProvider ref="country" rules="required"
                                                        v-slot="{ errors, valid }">
                                        <v-select v-model="country" :error="!valid" :error-messages="errors"
                                                  :disabled="loading" :items="countryItems"
                                                  item-text="name" item-value="id"
                                                  :label="$t('country')"
                                                  outlined
                                                  solo
                                                  flat
                                                  dense
                                                  color="primary"
                                                  class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                  clearable>
                                        </v-select>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="2">
                                    <ValidationProvider ref="code" rules="min:1|max:30"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="code" type="text" :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('code')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                                <v-col class="py-0" cols="12" sm="6">
                                    <ValidationProvider ref="station_name" rules="required|min:1|max:255"
                                                        v-slot="{ errors, valid }">
                                        <v-text-field v-model="station_name" type="text" :error="!valid" :error-messages="errors"
                                                      :disabled="loading"
                                                      :label="$t('station_name')"
                                                      outlined
                                                      solo
                                                      flat
                                                      dense
                                                      color="primary"
                                                      class="ma-0 pa-0 my-0 py-0 elevation-0 rounded-lg input_text"
                                                      clearable>>
                                        </v-text-field>
                                    </ValidationProvider>
                                </v-col>
                            </v-row>
                            <v-row style="background-color: #F5F5F5" class="pa-2">
                                <v-col cols="12"> {{ $t('station_center') }}</v-col>
                                <v-col cols="12">
                                    <yandex-map v-if="coords && showMap" ref="map"
                                                :coords="coords"
                                                :show-all-markers="showAllMarkers"
                                                :zoom="zoom"
                                                @map-was-initialized="initHandler"
                                                style="height: 300px"
                                                @click="onClickMap">
                                        <ymap-marker ref="mapMarker"
                                                     marker-id="123"
                                                     marker-type="placemark"
                                                     :coords="coords"
                                                     @dragend="ondDagendMap"/>
                                    </yandex-map>
                                </v-col>
                                <v-col cols="12" v-if="(coords[0] === 51 && coords[1] === 71)"
                                       class="mt-2" style="color: red">
                                    {{ $t('coordinates_of_point_not_set') }}
                                </v-col>
                            </v-row>

                        </v-card-text>

                        <v-card-actions class="px-4 py-7">
                            <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                                   :block="$vuetify.breakpoint.xsOnly" color="primary" class="infinity_button">
                                {{ $t('save') }}
                            </v-btn>
                            <v-btn
                                :disabled="loading"
                                class="ma-1 button_cancel"
                                plain
                                @click="closeDialogStation()"
                            >
                                {{$t('cancel')}}
                            </v-btn>
                            <v-spacer v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                            <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                               :active="loading" class="mx-2"></v-progress-linear>

                        </v-card-actions>

                    </v-card>
                </ValidationObserver>
            </v-form>
        </v-dialog>

    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex";
import debounce from "lodash/debounce";
import {loadYmap, yandexMap, ymapMarker} from 'vue-yandex-maps'

export default {
    name: 'TrackingForm',
    components: {
        ValidationProvider,
        ValidationObserver,
        yandexMap,
        ymapMarker
    },
    inject: ['forceRerender'],
    data() {
        return {
            dialogForm:true,
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            id: null,
            tracking_uuid: null,
            container_number: null,
            platform_number: null,
            platform_number_knr: null,
            comment: null,
            status: null,
            statusItems:[],
            loadingStatuses: false,
            sub_status: null,
            subStatusItems:[],
            loadingSubStatuses: false,
            date_border: null,
            dateMenuBorder: false,
            date_gr: null,
            dateMenuGr: false,
            date_pr: null,
            dateMenuPr: false,
            date_sending:null,
            menuDateSending: false,
            distance: null,
            departure: null,
            departureItems:[],
            departureSearching: null,
            loadingDepartures: false,
            destination: null,
            destinationItems:[],
            destinationSearching: null,
            loadingDestination: false,
            type_container: null,
            typeContainerItems:[],
            loadingTypeContainer: null,
            loading_type: null,
            loadingTypeItems:[],
            loadingLoadingType: null,
            type_transport: null,
            typeTransportItems:[],
            loadingTypeTransport: null,
            uved: null,
            uvedItems: [],
            uvedSearching: null,
            loadingUveds: false,
            disableUveds: false,
            deal: null,
            dealItems: [],
            dealSearching: null,
            loadingDeals: false,
            disableDeals: false,
            loadingTrackingTypes: false,
            loadingDeal: false,

            dialogFormStation: false,
            code: null,
            station_name: null,
            exist_translations: {},
            all_translations: true,
            showAllMarkers: false,
            mapMarker: null,
            zoom: 12,
            ymaps: null,
            coordinates: [],
            showMap: false,
            country: null,
            countryItems: [],
            add_type_station: null,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang']),
        languages() {
            return this.listLanguages
        },
        coords: {
            get: function () {
                return (this.coordinates && this.coordinates.length === 2) ? this.coordinates : [51, 71]
            },
            set: function (val) {
                this.coordinates = (val && val.length === 2) ? val : [51, 71]
            }
        },
        mapSettings() {
            this.$yandex.lang = 'ru_RU'
            return this.$yandex
        },
    },
    async mounted() {
        this.language = this.languages[this.tab]
        await this.getStatus()
        await this.getTypeContainer()
        await this.getTypeTransport()
        await this.getLoadingType()
        await this.getCountries()
        await this.checkCreate()
        await this.ymapInit()

    },
    watch: {
        uvedSearching: debounce(function (val) {
            if (val && !this.uved) {
                this.getUveds(val)
            }
        }, 500),
        dealSearching: debounce(function (val) {
            if (val) {
                this.getDeals(val)
            }
        }, 500),
        departureSearching: debounce(function (val) {
            if (val && !this.departure) {
                this.getDepartures(val)
            }
        }, 500),
        destinationSearching: debounce(function (val) {
            if (val && !this.destination) {
                this.getDestination(val)
            }
        }, 500),
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "tracking.edit") {
                this.heading = this.$t('tracking_editing')
                if (this.$route.params.uuid) {
                    this.getTracking()
                }
            }
            else
            {
                this.heading = this.$t('tracking_creation')
                if (this.$route.params.deal_uuid) {
                    this.getDeal(this.$route.params.deal_uuid)
                    this.getDealKp();
                }

            }
        },
        closeDialogAdd(){
            if(window.history.length > 2){
                this.$router.back()
            }
            else{
                this.$router.push({
                    name: 'tracking',
                })
            }
        },
        async getTracking() {
            var _this = this;
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/tracking/${this.$route.params.uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.tracking_uuid = res.body.data.uuid
                    this.deal = (res.body.data.deal && res.body.data.deal.id) ? res.body.data.deal.id : null
                    this.dealItems = [res.body.data.deal]
                    this.container_number = res.body.data.container_number
                    this.platform_number = res.body.data.platform_number
                    this.platform_number_knr = res.body.data.platform_number_knr
                    this.date_sending = res.body.data.date_sending ? this.$moment(res.body.data.date_sending).format('YYYY-MM-DD') : null;
                    this.date_border = res.body.data.date_border ? this.$moment(res.body.data.date_border).format('YYYY-MM-DD') : null;
                    this.date_gr = res.body.data.date_gr ? this.$moment(res.body.data.date_gr).format('YYYY-MM-DD') : null;
                    this.date_pr = res.body.data.date_pr ? this.$moment(res.body.data.date_pr).format('YYYY-MM-DD') : null;
                    this.distance = res.body.data.distance
                    this.departure = (res.body.data.departure && res.body.data.departure.id) ? res.body.data.departure.id : null;
                    this.departureItems = [res.body.data.departure]
                    this.destination = (res.body.data.destination && res.body.data.destination.id) ? res.body.data.destination.id : null;
                    this.destinationItems = [res.body.data.destination]
                    this.type_container = (res.body.data.type_container && res.body.data.type_container.id) ? res.body.data.type_container.id : null
                    this.loading_type = (res.body.data.loading_type && res.body.data.loading_type.id) ? res.body.data.loading_type.id : null;
                    this.type_transport = (res.body.data.type_transport && res.body.data.type_transport.id) ? res.body.data.type_transport.id : null
                    this.status = (res.body.data.status && res.body.data.status.id) ? res.body.data.status.id : null;
                    this.sub_status = (res.body.data.sub_status && res.body.data.sub_status.id) ? res.body.data.sub_status.id : null
                    this.subStatusItems = [res.body.data.sub_status]
                    this.uved = ( res.body.data.uved && res.body.data.uved.id ) ? res.body.data.uved.id : null
                    this.uvedItems = [res.body.data.uved]
                    this.comment = res.body.data.comment
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_tracking'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async saveTracking() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.languages && this.languages[this.tab]) {
                formData.append('language', this.languages[this.tab])
            }
            if (this.container_number) {
                formData.append('container_number', this.container_number)
            }
            if (this.platform_number) {
                formData.append('platform_number', this.platform_number)
            }
            if (this.platform_number_knr) {
                formData.append('platform_number_knr', this.platform_number_knr)
            }
            if (this.date_sending) {
                formData.append('date_sending', this.date_sending)
            }
            if (this.date_border) {
                formData.append('date_border', this.date_border)
            }
            if (this.date_gr) {
                formData.append('date_gr', this.date_gr)
            }
            if (this.date_pr) {
                formData.append('date_pr', this.date_pr)
            }
            if (this.distance) {
                formData.append('distance', this.distance)
            }
            if (this.comment) {
                formData.append('comment', this.comment)
            }
            if (this.departure) {
                if (this.departure.id) {
                    formData.append('departure', this.departure.id)
                } else {
                    formData.append('departure', this.departure)
                }
            }
            if (this.destination) {
                if (this.destination.id) {
                    formData.append('destination', this.destination.id)
                } else {
                    formData.append('destination', this.destination)
                }
            }
            if (this.type_container) {
                if (this.type_container.id) {
                    formData.append('type_container', this.type_container.id)
                } else {
                    formData.append('type_container', this.type_container)
                }
            }
            if (this.loading_type) {
                if (this.loading_type.id) {
                    formData.append('loading_type', this.loading_type.id)
                } else {
                    formData.append('loading_type', this.loading_type)
                }
            }
            if (this.type_transport) {
                if (this.type_transport.id) {
                    formData.append('type_transport', this.type_transport.id)
                } else {
                    formData.append('type_transport', this.type_transport)
                }
            }
            if (this.status) {
                if (this.status.id) {
                    formData.append('status', this.status.id)
                } else {
                    formData.append('status', this.status)
                }
            }
            if (this.sub_status) {
                if (this.sub_status.id) {
                    formData.append('sub_status', this.sub_status.id)
                } else {
                    formData.append('sub_status', this.sub_status)
                }
            }

            if (this.uved) {
                if (this.uved.id) {
                    formData.append('uved', this.uved.id)
                } else {
                    formData.append('uved', this.uved)
                }
            }
            if (this.deal) {
                if (this.deal.id) {
                    formData.append('deal', this.deal.id)
                } else {
                    formData.append('deal', this.deal)
                }
            }

            if (this.tracking_uuid ) {
                await this.$http
                    .put(`admin/tracking/${this.tracking_uuid}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('tracking_has_been_updated'))

                        //this.forceRerender()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('tracking_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/tracking', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('tracking_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.uuid) {
                            this.closeDialogAdd()
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('tracking_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        showTracking(uuid) {
            this.$router.push({
                name: 'tracking.show',
                params: {
                    id: uuid
                }
            })
        },
        clearUveds() {
            if (!this.uved) {
                this.uvedItems = []
            }
        },
        redirectToUved(val) {
            if (val) {
                this.$router.push({
                    name: 'uved.edit',
                    params: {
                        id: val
                    }
                })
            }
        },
        async getUveds(str) {
            if (str) {
                this.loadingUveds = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.uved = str
                }
                await this.$http
                    .get("admin/uved", {
                        params: params,
                    })
                    .then(res => {
                        this.uvedItems = res.body.data
                    })
                    .catch(err => {
                        this.uvedItems = []
                    })
                    .finally(end => {
                        this.loadingUveds = false
                    })
            }
        },
        clearDeals() {
            if (!this.deal) {
                this.dealItems = []
            }
        },
        async getDeals(str) {
            if (str) {
                this.loadingDeals = true
                let params = {}
                params.filter = 'search'
                if (str !== 'undefined') {
                    params.deal = str
                }
                await this.$http
                    .get("admin/deal", {
                        params: params,
                    })
                    .then(res => {
                        this.dealItems = res.body.data
                    })
                    .catch(err => {
                        this.dealItems = []
                    })
                    .finally(end => {
                        this.loadingDeals = false
                    })
            }
        },
        async getDeal(deal_uuid) {
            if (deal_uuid) {
                this.loadingDeal = true
                await this.$http
                    .get("admin/deal/"+deal_uuid)
                    .then(res => {
                        this.deal = res.body.data.id
                        this.dealItems = [res.body.data]
                        this.uved = res.body.data.uved.id
                        this.uvedItems = [res.body.data.uved]
                        this.disableUveds = true
                        this.disableDeals = true
                    })
                    .catch(err => {
                        this.dealItems = []
                        this.uvedItems = []
                    })
                    .finally(end => {
                        this.loadingDeal = false
                    })
            }
        },
        async getDealKp() {
            var _this = this;
            this.progressDeal = 0
            this.loadingDeal = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/deal_kp/${this.$route.params.deal_uuid}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progressDeal = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.loading_type = res.body.data.loading_type;
                    this.type_transport = res.body.data.type_transport;
                    this.type_container = res.body.data.type_container;
                    this.destination = res.body.data.destination;
                    this.destinationItems = [res.body.data.destination];
                    this.departure = res.body.data.departure;
                    this.departureItems = [res.body.data.departure];

                })
                .catch(err => {

                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(this.$t('failed_to_get_deal'))
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progressDeal = 0
                    this.loadingDeal = false
                })
        },
        async getStatus() {
            this.loadingStatuses = true
            let params = {};
            params.filter = 'search'

            await this.$http
                .get("admin/status", {
                    params: params,
                })
                .then(res => {
                    this.statusItems = res.body.data;
                })
                .catch(err => {
                    this.statusItems = []
                    this.$toastr.error(this.$t('failed_to_get_status'))
                })
                .finally(end => {
                    this.loadingStatuses = false
                })
        },
        async getSubStatus() {
            if(this.status && this.status > 0){
                this.loadingSubStatuses = true
                let params = {};
                params.filter = 'search'
                params.status_id = this.status

                await this.$http
                    .get("admin/sub_status", {
                        params: params,
                    })
                    .then(res => {
                        this.subStatusItems = res.body.data;
                    })
                    .catch(err => {
                        this.subStatusItems = []
                        this.$toastr.error(this.$t('failed_to_get_sub_status'))
                    })
                    .finally(end => {
                        this.loadingSubStatuses = false
                    })
            }

        },
        async getTypeContainer() {
            this.loadingTypeContainer = true
            let params = {};
            params.filter = 'search'

            await this.$http
                .get("admin/type_container", {
                    params: params,
                })
                .then(res => {
                    this.typeContainerItems = res.body.data;
                })
                .catch(err => {
                    this.typeContainerItems = []
                    this.$toastr.error(this.$t('failed_to_get_type_container'))
                })
                .finally(end => {
                    this.loadingTypeContainer = false
                })
        },
        async getDepartures(str) {
            this.loadingDepartures = true
            let params = {};
            params.filter = 'search'
            if (str !== 'undefined') {
                params.station_name = str;
            }

            await this.$http
                .get("admin/station", {
                    params: params,
                })
                .then(res => {
                    this.departureItems = res.body.data;
                })
                .catch(err => {
                    this.departureItems = []
                    this.$toastr.error(this.$t('failed_to_get_station'))
                })
                .finally(end => {
                    this.loadingDepartures = false
                })
        },
        clearDepartures() {
            if (!this.departure) {
                this.departuresItems = []
            }
        },
        async getDestination(str) {
            this.loadingDestination = true
            let params = {};
            params.filter = 'search'
            if (str !== 'undefined') {
                params.station_name = str;
            }

            await this.$http
                .get("admin/station", {
                    params: params,
                })
                .then(res => {
                    this.destinationItems = res.body.data;
                })
                .catch(err => {
                    this.destinationItems = []
                    this.$toastr.error(this.$t('failed_to_get_station'))
                })
                .finally(end => {
                    this.loadingDestination = false
                })
        },
        clearDestination() {
            if (!this.destination) {
                this.destinationItems = []
            }
        },
        async getTypeTransport() {
            this.loadingTypeTransport = true
            let params = {};
            params.filter = 'search'

            await this.$http
                .get("admin/type_transport", {
                    params: params,
                })
                .then(res => {
                    this.typeTransportItems = res.body.data;
                })
                .catch(err => {
                    this.typeTransportItems = []
                    this.$toastr.error(this.$t('failed_to_get_type_transport'))
                })
                .finally(end => {
                    this.loadingTypeTransport = false
                })
        },
        async getLoadingType() {
            this.loadingLoadingType = true
            let params = {};
            params.filter = 'search'

            await this.$http
                .get("admin/loading_type", {
                    params: params,
                })
                .then(res => {
                    this.loadingTypeItems = res.body.data;
                })
                .catch(err => {
                    this.loadingTypeItems = []
                    this.$toastr.error(this.$t('failed_to_get_loading_type'))
                })
                .finally(end => {
                    this.loadingLoadingType = false
                })
        },

        async ymapInit() {
            var _this = this
            if (window.ymaps) {
                this.showMap = false
                if (this.$refs.map && this.$refs.map.myMap) {
                    this.$refs.map.myMap.destroy()
                }
                document.getElementById("vue-yandex-maps").remove()
                delete window.ymaps
            }
            await loadYmap({...this.mapSettings, debug: true})
            if (window.ymaps) {
                window.ymaps.ready(function () {
                    _this.showMap = true
                })
            }
        },
        initHandler(e) {
            if (this.coords && this.coords.length !== 2 && window.ymaps) {
                var location = window.ymaps.geolocation.get({
                    provider: 'yandex',
                    mapStateAutoApply: true,
                    autoReverseGeocode: true
                })
                location.then(
                    function (result) {
                        //console.log(result.geoObjects.get(0).geometry.getCoordinates())
                        e.geoObjects.add(result.geoObjects)
                    },
                    function (err) {
                        console.log('Error: ' + err)
                    }
                )
            }
            e.geoObjects.options.set('draggable', true)

        },
        onClickMap(e) {
            var _this = this
            this.coords = e.get('coords')
            var myGeocoder = window.ymaps.geocode(this.coords)
            myGeocoder.then(
                function (res) {
                    let GeocoderMetaData = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
                    if (GeocoderMetaData.Address.Components) {
                        let Components = GeocoderMetaData.Address.Components
                        let address_full = []
                        let street_full = []
                        let district = Components.filter(item => item.kind === 'district')
                        if (district.length > 0) {
                            address_full.push(district[0].name)
                            street_full.push(district[0].name)
                        }
                        let street = Components.filter(item => item.kind === 'street')
                        if (street.length > 0) {
                            address_full.push(street[0].name)
                            street_full.push(street[0].name)
                        }
                        let house = Components.filter(item => item.kind === 'house')
                        if (house.length > 0) {
                            address_full.push(house[0].name)
                            _this.house = house[0].name
                        }
                        _this.street = street_full.join(', ')
                        _this.address = address_full.join(', ')
                    }

                },
                function (err) {
                    console.log('Error Load GeocoderMetaData')
                }
            )
            //this.zoom = 16
        },
        ondDagendMap(e) {
            var _this = this
            this.coords = e.get('target').geometry.getCoordinates()
            var myGeocoder = window.ymaps.geocode(this.coords)
            myGeocoder.then(
                function (res) {
                    let GeocoderMetaData = res.geoObjects.get(0).properties.get('metaDataProperty').GeocoderMetaData
                    if (GeocoderMetaData.Address.Components) {
                        let Components = GeocoderMetaData.Address.Components
                        let address_full = []
                        let street_full = []
                        let district = Components.filter(item => item.kind === 'district')
                        if (district.length > 0) {
                            address_full.push(district[0].name)
                            street_full.push(district[0].name)
                        }
                        let street = Components.filter(item => item.kind === 'street')
                        if (street.length > 0) {
                            address_full.push(street[0].name)
                            street_full.push(street[0].name)
                        }
                        let house = Components.filter(item => item.kind === 'house')
                        if (house.length > 0) {
                            address_full.push(house[0].name)
                            _this.house = house[0].name
                        }
                        _this.street = street_full.join(', ')
                        _this.address = address_full.join(', ')
                    }

                },
                function (err) {
                    console.log('Error Load GeocoderMetaData')
                }
            )
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        async getCountries() {
            var _this = this
            let params = {}
            params.list = 'all'
            await this.$http.get(`admin/country`, {
                    params: params,
                }
            )
                .then(res => {
                    this.countryItems = res.body.data
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_countries'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
        },
        async saveStation() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }
            if (this.station_name) {
                formData.append('station_name', this.station_name)
            }
            if (this.code) {
                formData.append('code', this.code)
            }
            if (this.coords && this.coords.length > 0) {
                for (let i in this.coords) {
                    formData.append(`location[${i}]`, this.coords[i])
                }
            }
            if (this.country) {
                formData.append('country', this.country)
            }

            // Add
            await this.$http
                .post('admin/station', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    },
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.$toastr.success(this.$t('station_has_been_added'))
                    this.closeDialogStation();
                })
                .catch(err => {
                    this.$toastr.error(this.$t('station_has_not_been_added'))
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })

        },
        closeDialogStation(){
            this.dialogFormStation = false;
            this.code = null;
            this.station_name =  null;
            this.exist_translations = {};
            this.all_translations = true;
            this.showAllMarkers = false;
            this.mapMarker = null;
            this.coordinates = [];
            this.zoom = 12;
            this.country = null;
            this.add_type_station = null;
        }

    }
}
</script>

